import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import CategoryComplement from "../entities/pdv/categoryComplement.entity";
import EstablishmentDB from "./establishment";
import FirestorePipe from "../utils/pipe";
interface ComplementPicture {
  image_complement?: Blob;
  image_complement_ref: string;
  image_complement_url: string;
}

class CategoryComplementDB extends FirestorePipe {

  public static readonly colName = 'CategoriesComplement'

  constructor(idEstab: string) {
    if (!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${CategoryComplementDB.colName}`);
  }

  public async create(data: CategoryComplement): Promise<any> {
    await this.saveFile(data);
    try {
      return await this._create(data);
    } catch (error: any) {
      data.complements_picture.map(async (item: any) => {
        if (item.image_complement_ref) {
          await this.deleteFile(item.image_complement_ref)
        }
      });
      throw error.message
    }
  }
  public async update(id: string, data: CategoryComplement): Promise<any> {
    await this.saveFile(data);
    return await this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<CategoryComplement[]> {
    return this._getAll<CategoryComplement>(...params);
  }
  public get(id: string): Promise<CategoryComplement> {
    return this._get(id);
  }
  public on(listener: (data: CategoryComplement[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

  private async saveFile(data: CategoryComplement) {
    if (data.complements_picture) {
      for (const item of data.complements_picture) {
        if (item.image_complement) {
          const resultUpload = await this.uploadFile(new Date().getTime().toString(), item.image_complement);
          item.image_complement_ref = resultUpload.ref.fullPath;
          item.image_complement_url = resultUpload.url;
          delete item.image_complement;
        }
      }

    }
  }

}

export default CategoryComplementDB;