import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import Product from "../entities/pdv/product.entity";
import EstablishmentDB from "./establishment";
import FirestorePipe from "../utils/pipe";

class ProductDB extends FirestorePipe {

  public static readonly colName = 'Products'

  constructor(idEstab: string) {
    if(!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${ProductDB.colName}`);
  }

  public writeBatch(datas: Product[]): Promise<void> {
  return this._writeBatch<Product>(datas)
  }

  public async create(data: Product): Promise<any> {
    await this.saveFile(data);
    try {
      return await this._create(data);
    } catch (error: any) {
      if(data.image_ref) {
        await this.deleteFile(data.image_ref)
      }
      if(data.banner_ref) {
        await this.deleteFile(data.banner_ref)
      }
      throw error.message
    }
  }
  public async update(id: string, data: Product): Promise<any> {
    await this.saveFile(data);
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<Product[]> {
    return this._getAll<Product>(...params);
  }
  public get(id: string): Promise<Product> {
    return this._get(id);
  }
  public on(listener: (data: Product[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

  private async saveFile(data: Product) {
    if(data.image) {
      const resultUpload = await this.uploadFile(new Date().getTime().toString(), data.image);
      data.image_ref = resultUpload.ref.fullPath;
      data.image_url = resultUpload.url;
    }

    if(data.banner) {
      const resultUpload = await this.uploadFile(new Date().getTime().toString(), data.banner);
      data.banner_ref = resultUpload.ref.fullPath;
      data.banner_url = resultUpload.url;
    }
    delete data.image
    delete data.banner
  }

}

export default ProductDB;