// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAuzCNJodKXc8zC8mokJTt3y86LhU594oI",
  authDomain: "ugetpdx-dev.firebaseapp.com",
  projectId: "ugetpdx-dev",
  storageBucket: "ugetpdx-dev.appspot.com",
  messagingSenderId: "71357489450",
  appId: "1:71357489450:web:ff2a37031475c71a097e53",
  measurementId: "G-JMLTW37VJN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;