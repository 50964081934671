import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import Complement from "../entities/pdv/complement.entity";
import EstablishmentDB from "./establishment";
import FirestorePipe from "../utils/pipe";

class ComplementDB extends FirestorePipe {

  public static readonly colName = 'Complements'

  constructor(idEstab: string) {
    if(!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${ComplementDB.colName}`);
  }

  public writeBatch(datas: Complement[]): Promise<void> {
    return this._writeBatch<Complement>(datas)
  }

  public async create(data: Complement): Promise<any> {
    await this.saveFile(data);
    try {
      return await this._create(data);
    } catch (error: any) {
      if(data.image_ref) {
        await this.deleteFile(data.image_ref)
      }
      if(data.second_image_ref) {
        await this.deleteFile(data.second_image_ref)
      }
      throw error.message
    }
  }
  public async update(id: string, data: Complement): Promise<any> {
    await this.saveFile(data);
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<Complement[]> {
    return this._getAll<Complement>(...params);
  }
  public get(id: string): Promise<Complement> {
    return this._get(id);
  }
  public on(listener: (data: Complement[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

  private async saveFile(data: Complement) {
    if(data.image) {
      const resultUpload = await this.uploadFile(new Date().getTime().toString(), data.image);
      data.image_ref = resultUpload.ref.fullPath;
      data.image_url = resultUpload.url;
    }
    delete data.image

    if(data.second_image){
      const resultSecondUpload = await this.uploadFile(new Date().getTime().toString(), data.second_image);
      data.second_image_ref = resultSecondUpload.ref.fullPath;
      data.second_image_url = resultSecondUpload.url;
    }

    delete data.second_image
  }

}

export default ComplementDB;